import * as THREE from 'three'

// function mainDark () {
//   const canvas = document.getElementById('background')
//   // GL コンテキストを初期化する
//   const gl = canvas.getContext('webgl')

//   // WebGL が使用可能で動作している場合にのみ続行します
//   if (gl === null) {
//     alert('WebGL を初期化できません。ブラウザーまたはマシンがサポートしていない可能性があります。')
//     return
//   }

//   // クリアカラーを黒に設定し、完全に不透明にします
//   gl.clearColor(0.0, 0.0, 0.0, 1.0)
//   // 指定されたクリアカラーでカラーバッファをクリアします
//   gl.clear(gl.COLOR_BUFFER_BIT)
// }

function mainWhite () {
  const canvas = document.getElementById('background')
  // GL コンテキストを初期化する
  const gl = canvas.getContext('webgl')

  // WebGL が使用可能で動作している場合にのみ続行します
  if (gl === null) {
    alert('WebGL を初期化できません。ブラウザーまたはマシンがサポートしていない可能性があります。')
    return
  }

  // クリアカラーを黒に設定し、完全に不透明にします
  gl.clearColor(1.0, 1.0, 1.0, 1.0)
  // 指定されたクリアカラーでカラーバッファをクリアします
  gl.clear(gl.COLOR_BUFFER_BIT)
}

function mainDark () {
  const canvas = document.getElementById('background')
  const width = canvas.clientWidth
  const height = canvas.clientHeight

  const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    devicePixelRatio: window.devicePixelRatio
  })
  renderer.setSize(width, height)

  // シーンを作成
  const scene = new THREE.Scene()

  // フォグを追加
  scene.fog = new THREE.Fog(0x000000, 100, 2000)

  // カメラを作成
  const camera = new THREE.PerspectiveCamera(45, width / height, 1, 10000)
  camera.position.set(0, 0, 10)

  // グループを作成
  const group = new THREE.Group()
  scene.add(group)

  const geometry = new THREE.SphereGeometry(8, 32, 32)
  const material = new THREE.MeshStandardMaterial()

  for (let i = 0; i < 1000; i++) {
    const mesh = new THREE.Mesh(geometry, material)
    mesh.position.x = (Math.random() - 0.5) * 2000
    mesh.position.y = (Math.random() - 0.5) * 2000
    mesh.position.z = (Math.random() - 0.5) * 2000
    mesh.rotation.x = Math.random() * 2 * Math.PI
    mesh.rotation.y = Math.random() * 2 * Math.PI
    mesh.rotation.z = Math.random() * 2 * Math.PI
    group.add(mesh)
  }

  // 光源
  const directionalLight = new THREE.DirectionalLight(0xFF0000, 2)
  const ambientLight = new THREE.AmbientLight(0xFFFFFF)
  scene.add(directionalLight)
  scene.add(ambientLight)

  tick()

  function tick () {
    // group.rotateY(0.01)
    renderer.render(scene, camera)

    requestAnimationFrame(tick)
  }
}

export { mainDark, mainWhite }
