<template>
<v-card
  tile
  class="overflow-hidden">
  <v-app id="inspire" :dark="darkMode">
    <v-app-bar
      app
      color="primary"
      src="../../assets/appBarDark.svg"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
        ></v-img>
      </template>

      <v-app-bar-nav-icon class="primaryChar--text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title class="barTitle primaryChar--text textno-wrap">
        <h1>HaneHaneHane&nbsp;</h1>
      </v-app-bar-title>

      <v-spacer></v-spacer>

    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      app
    >
      <v-list
        nav
        dense
      >
        <v-spacer></v-spacer>
        <v-list-item>
          <v-switch v-model="darkTheme" :prepend-icon="themeIcon" color="Success"></v-switch>
        </v-list-item>
        <v-divider></v-divider>

        <v-list-item-group
          v-model="group"
          mandatory
          active-class="third--text text--accent-4"
        >
          <v-list-item href="/" key="0">
            <v-list-item-title>Top</v-list-item-title>
          </v-list-item>

          <v-list-item href="/blog">
            <v-list-item-title>Blog</v-list-item-title>
          </v-list-item>

          <v-list-item href="/portfolio">
            <v-list-item-title>Portfolio</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <canvas id="background"></canvas>
    <section id="main">
    <v-main>
      <router-view/>
    </v-main>
    </section>
  </v-app>
</v-card>
</template>

<script>
import { mainDark, mainWhite } from '../../assets/js/top_background.js'

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    group: 0,
    darkTheme: true,
    saveDate: 60 * 60 * 24 * 30,
    t: 'true'
  }),

  methods: {
    changeMode: function (dark) {
      this.$cookies.set('darkmode', dark, this.saveDate)
      this.$vuetify.theme.dark = dark
    },
    ModeResult: function (dark, white) {
      if (this.darkTheme === true) {
        return dark
      } else {
        return white
      }
    },
    initDarkModeCookie: function () {
      const dakrCookie = this.$cookies.get('darkmode')
      if (dakrCookie === null) {
        let tmpMode = window.matchMedia('(prefers-color-scheme: dark)').matches
        if (tmpMode === null) {
          tmpMode = true
        }
        this.$cookies.set('darkmode', tmpMode, this.saveDate)
        return tmpMode
      } else {
        return (dakrCookie === this.t)
      }
    }
  },

  created () {
    this.darkTheme = this.initDarkModeCookie()
    if (this.darkTheme) {
      this.$vuetify.theme.dark = true
      window.onload = mainDark
    } else {
      this.$vuetify.theme.dark = false
      window.onload = mainWhite
    }
  },

  destroyed () {
    this.$cookies.set('darkmode', this.darkTheme, this.saveDate)
  },

  computed: {
    themeIcon: function () {
      return this.ModeResult('mdi-weather-night', 'mdi-weather-sunny')
    },
    darkMode: function () {
      this.changeMode(this.darkTheme)
      return this.$vuetify.theme.dark
    }
  },

  watch: {
    darkTheme: function () {
      if (this.darkTheme === true) {
        mainDark()
      } else {
        mainWhite()
      }
    }
  }
}
</script>

<style scoped>
.barTitle{
  font-family: 'Orbitron', sans-serif;
}
</style>
