import Vue from 'vue'
import Top from './Top.vue'
import router from '../../router/top'
import vuetify from '../../plugins/vuetify'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false
Vue.use(VueCookies)

new Vue({
  vuetify,
  router,
  render: h => h(Top)
}).$mount('#top')
