import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0f317e',
        primaryChar: '#fbfbff',
        secondary: '#4f7898',
        third: '#1fa0bb',
        fourth: '#76eee1',
        fifth: '#78fdca',
        accent: '#1469aa',
        error: '#1BE3CE',
        warning: '#14aa9a',
        info: '#156b7d',
        success: '#78a1fd',
        mycolor: '#1EFDA7',
        background: '#eaefff',
        invertChar: '#FFFFFF'
      },
      // Color Pallet 白40
      dark: {
        primary: 'a5c0fe',
        primaryChar: '#000008',
        secondary: '#1469aa',
        third: '#7fe6fc',
        fourth: '#14aa9a',
        fifth: '#16bd7d',
        accent: '#1469aa',
        error: '#1BE3CE',
        warning: '#14aa9a',
        info: '#7fe6fc',
        success: '#78a1fd',
        mycolor: '#1EFDA7',
        background: '#000018',
        invertChar: '#212121'
      }
    }
  }
})
